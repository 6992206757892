import React from 'react';
import { graphql } from 'gatsby';
import { getKeyValue } from '@utils';
import { DARK } from '@constants';
import { Layout, HomepageHero, HomepageContent } from '@components';

const HomepageTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, settings,
  } = data;

  const { locale } = pageContext;
  const { social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    title, description, metaImage, path, pageContent,
  } = page || {};
  const {
    titleFill, titleOutline, subtitle, tickerTape, sections,
  } = pageContent;
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const heroProps = { titleFill, titleOutline, subtitle };
  const contentProps = { tickerTape, sections };

  return (
    <Layout
      location={location}
      metaDescription={description}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${path}`}
      metaTitle={`${title}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      locale={locale}
      navTheme={DARK}
      settings={settings}
    >
      <HomepageHero {...heroProps} />
      <HomepageContent {...contentProps} />
    </Layout>
  );
};

export default HomepageTemplate;

export const homepageQuery = graphql`
  query HomepageBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(path: { eq: $slug }, node_locale: { eq: $locale }) {
      ...PageMetadata
      pageContent {
        ... on ContentfulHomepage {
          titleFill
          titleOutline
          id
          subtitle {
            raw
          }
          sections {
            id
            content {
              raw
              references {
                ... on ContentfulForm {
                  contentful_id
                  id
                  internal {
                    type
                  }
                  postUrl
                  submitButtonLabel
                  postSubmitMessage {
                    raw
                  }
                  contentfulfields {
                    label
                    name
                    required
                    id
                    type
                    desktopWidth
                    errorMessage
                    placeholder
                    selectradioOptions {
                      value
                      key
                    }
                  }
                }
                ... on ContentfulAsset {
                  contentful_id
                  __typename
                  file {
                    url
                    fileName
                    url
                    contentType
                    details {
                      size
                      image {
                        height
                        width
                      }
                    }
                  }
                  title
                  gatsbyImageData(
                    width: 249
                    quality: 90
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
            width
          }
          tickerTape {
            items
          }
        }
      }
    }
    settings: contentfulSiteSettings(node_locale: { eq: $locale }) {
      ...SiteSettings
    }
  }
`;
